import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgsRevealService } from 'ngx-scrollreveal';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';





@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  // public tab = 1;
  public svgStart = false;
  public svgStart1 = false;
  public getSectionID;
  public url;
  public afterRevealSubscription;
  public hoveredItem;
  public activeImg = false;
  public countPageBottom = 0;
  public scrollbarOptions = { axis: 'y', theme: 'minimal-dark' };
  constructor(private router: Router, private digService: DigitalService, private revealService: NgsRevealService) {

  }

  ngOnInit() {
    window.scroll(0, 0);
    this.afterRevealSubscription = this.revealService.beforeReveal$.subscribe(
      (el: HTMLElement) => {
        if (el.id == "svgAnim") {
          setTimeout(() => {
            this.svgStart = true;
          }, 1000);
        }
        else if (el.id == "think") {
          setTimeout(() => {
            this.svgStart1 = true;
          }, 100);
        }
      });


    // window.onscroll = (): void => {
    //   var d = document.documentElement;
    //   var offset = d.scrollTop + window.innerHeight;
    //   var height = d.offsetHeight;

    //   console.log('offset = ' + offset);
    //   console.log('height = ' + height);

    //   if (offset === height) {
    //     this.router.navigateByUrl("/capabilities");
    //   }
    // };
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}

