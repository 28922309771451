import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {
  public companyLogo;
  public hoveredItem;
  public activeImg = false;
  constructor(private digService: DigitalService) { }

  ngOnInit() {
    this.companyLogo = this.digService.companyLogo;
  }

  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}
