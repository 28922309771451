import { Component, OnInit, Renderer2 } from '@angular/core';
import { DigitalService } from '../digital.service';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public sideOpen = false;
  public url;
  public getSectionID;
  constructor(private digService: DigitalService, private router: Router, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.removeClass(document.body, 'nav-open');
    this.getSectionID = this.digService.setSectionID;
    this.url = this.digService.getUrl();
    if (this.getSectionID != undefined) {
      this.digService.triggerScrollTo(this.getSectionID);
    }
  }
  openSideMenu() {
    if (this.sideOpen === false) {

      this.sideOpen = true;
      this.renderer.addClass(document.body, 'nav-open');
    } else {
      this.sideOpen = false;
      this.renderer.removeClass(document.body, 'nav-open');
    }
  }
  setId(el) {
    this.digService.setSectionID = el;
  }

}

