import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { Router } from '@angular/router';
import { NgsRevealService } from 'ngx-scrollreveal';
import { ScrollEvent } from 'ngx-scroll-event';



@Component({
  selector: 'app-work-page',
  templateUrl: './work-page.component.html',
  styleUrls: ['./work-page.component.css']
})
export class WorkPageComponent implements OnInit {
  public dataPacket;
  public afterRevealSubscription;
  public hoveredItem;
  public activeImg = false;
  constructor(private router: Router, private digitalService: DigitalService) { }

  ngOnInit() {
    window.scroll(0, 0);
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}
