import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ExploreComponent } from './explore/explore.component';
import { FooterComponent } from './footer/footer.component';
import { WorkComponent } from './work/work.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RouterModule, Routes } from '@angular/router';
import { WorkPageComponent } from './work-page/work-page.component';
import { WorkFsComponent } from './work-fs/work-fs.component';
import { FilterComponent } from './filter/filter.component';
import { WorkPorfolioComponent } from './work-porfolio/work-porfolio.component';
import { HttpClientModule } from '@angular/common/http';
import { CapabilityPageComponent } from './capability-page/capability-page.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { WorkDetailsComponent } from './work-details/work-details.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ThinkComponent } from './think/think.component';
import { ScrollEventModule } from 'ngx-scroll-event';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { WorkDetailsTwoComponent } from './work-details-two/work-details-two.component';
import { SafePipeModule } from 'safe-pipe';
import { IsotopeModule } from 'ngx-isotope';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';


const appRoutes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'work', component: WorkPageComponent },
  { path: 'capabilities', component: CapabilityPageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'work-details/:id', component: WorkDetailsComponent },
  { path: 'work-details/apps/:id', component: WorkDetailsTwoComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ExploreComponent,
    FooterComponent,
    WorkComponent,
    HomePageComponent,
    WorkPageComponent,
    WorkFsComponent,
    FilterComponent,
    WorkPorfolioComponent,
    CapabilityPageComponent,
    AboutComponent,
    ContactComponent,
    WorkDetailsComponent,
    WhatWeDoComponent,
    ThinkComponent,
    WorkDetailsTwoComponent,
  ],
  imports: [
    BrowserModule,
    ScrollToModule.forRoot(),
    HttpClientModule,
    ScrollEventModule,
    SlickCarouselModule,
    NgbPaginationModule,
    NgsRevealModule,
    SafePipeModule,
    FormsModule,
    ReactiveFormsModule,
    IsotopeModule,
    MalihuScrollbarModule.forRoot(),
    BrowserAnimationsModule,
    HeadroomModule,
    RouterModule.forRoot(
      appRoutes,
      { scrollPositionRestoration: 'enabled' }
    ),

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
