import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgsRevealService } from 'ngx-scrollreveal';
import { ScrollEvent } from 'ngx-scroll-event';





@Component({
  selector: 'app-work-details',
  templateUrl: './work-details.component.html',
  styleUrls: ['./work-details.component.css']
})

export class WorkDetailsComponent implements OnInit {
  public data;
  public urlNumb;
  public innerData;
  public tempData;
  public userSubscription;
  public afterRevealSubscriptionNew1;
  public hoveredItem;
  public activeImg = false;
  constructor(private route: ActivatedRoute, private digService: DigitalService, private revealService: NgsRevealService, private router: Router) { }

  ngOnInit() {
    window.scroll(0, 0);
    // same component update using params
    this.userSubscription = this.route.params.subscribe(
      (params) => {
        this.innerData = params.id;
        this.tempData = this.digService.tempContentData[this.innerData];
        this.revealService.sync();
      });
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}

