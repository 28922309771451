import { Component, OnInit } from '@angular/core';
import {DigitalService} from '../digital.service';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  public sideOpen = false;
  public filterNames;
  constructor(private digServ: DigitalService) { }

  ngOnInit() {
    this.filterNames = this.digServ.filters;
  }

  openSideMenu() {
    if(this.sideOpen === false){
      this.sideOpen = true;
    }else{
      this.sideOpen = false;
    }
}
}
