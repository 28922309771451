import { Component, OnInit, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'q';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit {
  public tab = 1;
  public hoveredItem;
  public activeImg = false;
  public fromDataUrl = 'form-data.txt'; 
  model : any = {};
  model1 : any = {};
 
  
  constructor(private http: HttpClient, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document) { }
  
  
  ngOnInit() {
    window.scroll(0,0);
    let s = this._renderer2.createElement('script');
    s.type = `text/javascript`;
    s.text = `
              hbspt.forms.create({
	            portalId: "1723749",
              formId: "4d624ba1-cd4a-4c55-b42a-22b85717e704",
              target: "#hubspotForm",
              });
    `;
    this._renderer2.appendChild(this._document.body, s);
    let a = this._renderer2.createElement('script');
    a.type = `text/javascript`;
    a.text = `
              hbspt.forms.create({
	            portalId: "1723749",
              formId: "7bb95d28-e529-47a9-8ad7-44766f831c79",
              target: "#hubspotForm2",
              });
    `;
    this._renderer2.appendChild(this._document.body, a);
  }
ngAfterViewInit() {
  setTimeout(() => {
   // adding animation to form submit button
   var submitBtn1 = document.querySelector("#hubspotForm .hs-submit .actions");
   var submitBtn2 = document.querySelector("#hubspotForm2 .hs-submit .actions")
   submitBtn1.classList.add("btn-src");
   submitBtn2.classList.add("btn-src");
   // The HTML string to be appended
   var html = '<img _ngcontent-c3="" alt="" src="assets/images/tail-right.svg"><span class="line-1"></span><span class="line-2"></span><span class="line-3"></span><span class="line-4"></span>';
   
   // append
   submitBtn1.insertAdjacentHTML('beforeend', html);
   submitBtn2.insertAdjacentHTML('beforeend', html);
  }, 800);
   
  }
  setTab(newTab) {
    this.tab = newTab;
  }
  isSet(tabNum) {
    return this.tab === tabNum;
  }
  mouseover(e){
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e){
    this.hoveredItem = e;
    this.activeImg = false;
  }

}
