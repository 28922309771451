import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-think',
  templateUrl: './think.component.html',
  styleUrls: ['./think.component.css']
})
export class ThinkComponent implements OnInit {
  slideConfig = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
  };

  public hoveredItem;
  public activeImg = false;
  constructor() { }

  ngOnInit() {

  }

  // beforeChange(e) {
  //   if(e.nextSlide == e.slick.slideCount-1){
  //     this.slideConfig.dots = false;
  //     e.slick.$nextArrow[0].style.display = 'none';
  //   }
  //   else{
  //     e.slick.$nextArrow[0].style.display = 'block';
  //   }
  //   if(e.nextSlide == e.slick.slideCount-4){
  //     e.slick.$prevArrow[0].style.display = 'none';  
  //   }
  //   else{
  //     e.slick.$prevArrow[0].style.display = 'block';
  //   }
  // }
  // slickInit(e){
  //   if(e.slick.currentSlide == 0){
  //     e.slick.$prevArrow[0].style.display = 'none';  
  //   }
  //   else{
  //     e.slick.$prevArrow[0].style.display = 'block';
  //   }
  // }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}
