import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DigitalService } from '../digital.service';
import { NgsRevealService } from 'ngx-scrollreveal';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { DomSanitizer } from '@angular/platform-browser'
declare let jQuery: Object;

declare let $;
// import filterizr from 'filterizr'; import Filterizr from '../../../node_modules/filterizr';


@Component({
  selector: 'app-work-porfolio',
  templateUrl: './work-porfolio.component.html',
  styleUrls: ['./work-porfolio.component.css'],
})

export class WorkPorfolioComponent implements OnInit {

  public activeImg = false;
  slideConfig = {
    dots: true,
    slidesToShow: 3.05,
    slidesToScroll: 3,
    infinite: false,
    speed: 1000,
    useTransform: true,
    centerMode: false,
    responsive: [{
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false,
      }

    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      }
    }],

  };



  public toAdd = false;
  public toShowbanner = false;
  public animate = false;
  public animHeight = false;
  public selectedItem;
  public page;
  public currentPage = 1;
  public data: any = [];
  public urlNumber;
  public afterRevealSubscription;
  public hoveredItem;
  public showPopupContent = false;
  public workContent = [];
  public workPagetimer = [];
  public toRemoveClass = "";
  public innerWidth: any;
  public scrollbarOptions = {
    axis: 'x', theme: "dark-thin",
    autoExpandScrollbar: true,
  };


  constructor(private router: Router, public digService: DigitalService, private revealService: NgsRevealService, private mScrollbarService: MalihuScrollbarService, private sanitized: DomSanitizer) { }

  ngOnInit() {
    var container1 = $('#container1');
    $('#container1').mCustomScrollbar({
      axis: "x",
      theme: "dark-thick",
      advanced: {
        autoExpandHorizontalScroll: true,
      },
      autoDraggerLength: false,
      scrollbarPosition: "outside",

      setHeight: '70vh',
      setWidth: '50%',
      scrollInertia: 3000
    });





    // $("ul.content").mCustomScrollbar({
    //   axis: "x",
    //   theme: "light-3",
    //   advanced: { autoExpandHorizontalScroll: true }
    // });

    this.innerWidth = window.innerWidth;
    const slider = $(".work-page-carousel");
    // slider.on('wheel', (function (e) {
    //   e.originalEvent.preventDefault();

    //   if (e.originalEvent.deltaY > 0) {
    //     $(this).slick('slickNext');
    //     return false;
    //   } else if (e.originalEvent.deltaY < 0) {
    //     $(this).slick('slickPrev');
    //     return false;
    //   }
    //   else {
    //     e.originalEvent.preventDefault();
    //   }
    // }));

    this.animate = false;
    this.animHeight = false;
    this.toShowbanner = false;
    this.toRemoveClass = "";
    this.page = 1;
    this.showConfig();
    let wrapperLength = [] = $('#wrapper').children('div.portfolio');
    this.workCategoryClick('all');
    $(function () {
      //change filter buttons color
      $('.button-group').each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', 'button', function () {
          $buttonGroup.find('.is-checked').removeClass('is-checked');
          $(this).addClass('is-checked');
        });
      });

    });


  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }

  startAnim(event, pageUrl) {
    let landing = event.target.getAttribute('data-landing');
    let url = event.target.getAttribute('data-url');
    if (url == '/capabilities/') {
      this.router.navigate(['/capabilities/']);
    } else {
      const slider = $(".work-page-carousel");
      // $('.work-page-carousel').slick("unslick");
      document.querySelector(".work-filter-list")['style'].display = "none";
      document.querySelector(".see-cap-section")['style'].display = "none";
      $('#container1').mCustomScrollbar('destroy');

      // event.target.classList.add('toRemove');
      this.toRemoveClass = "toRemove";
      window.scroll(0, 0);

      setTimeout(() => {
        event.target.classList.remove('toRemove');
        setTimeout(() => {
          event.target.classList.add('toShow');
        }, 1);
      }, 20);
      setTimeout(() => {
        this.router.navigate([url, landing]);
      }, 1000);


    }

  }
  showConfig() {
    this.digService.getConfig()
      .subscribe((data) => this.data = data);
  }

  workCategoryClick(type) {
    $('#container1').mCustomScrollbar('destroy');
    var container1 = $('#container1');
    $('#container1').mCustomScrollbar({
      axis: "x",
      theme: "dark-thick",
      advanced: {
        autoExpandHorizontalScroll: true,
      },
      autoDraggerLength: false,
      scrollbarPosition: "outside",

      setHeight: '70vh',
      setWidth: '50%',
      scrollInertia: 3000
    });

    this.toRemoveClass = "";
    for (let j = 0; j < this.workPagetimer.length; j++) {
      clearTimeout(this.workPagetimer[j]);
    }
    this.workPagetimer = [];

    this.digService.workcontentFiltered = [];
    for (let i = 0; i < this.digService.workcontent.length; i++) {
      if ((this.digService.workcontent[i]['type'].indexOf(type) > -1) || type == 'all') {
        let thisvariable = this;
        var timer = setTimeout(function () {
          thisvariable.digService.workcontentFiltered.push(thisvariable.digService.workcontent[i]);
        }, 1 * (i + 1));

        this.workPagetimer.push(timer);

      }

    }
    const slider = $(".work-page-carousel");

    // slider.on('init reInit afterChange', function (event, slick, currentSlide) {

    //   this.innerWidth = window.innerWidth;
    //   let perSlide = 3;
    //   if (this.innerWidth <= 500) {
    //     perSlide = 1;

    //   }
    //   else if (this.innerWidth <= 768) {
    //     perSlide = 2;
    //   }

    //   const totalSlide = Math.ceil(slick.$slides.length / perSlide)
    //   const curSlide = Math.round((currentSlide / perSlide) + 1);
    //   if (curSlide >= totalSlide || type == '3d') {
    //     document.querySelector(".see-cap-section")['style'].display = "none";
    //   } else {
    //     document.querySelector(".see-cap-section")['style'].display = "block";
    //   }
    //   if (type == '3d') {
    //     document.querySelector(".slick-dots")['style'].display = "none";
    //   }
    //   else {
    //     document.querySelector(".slick-dots")['style'].display = "block";
    //   }

    // });

  }


}


