import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'rgx-dig';
  constructor() { };

  ngOnInit() {
    // GDPR script Starts
    var cookieName = 'regalix_gdpr_policy';
    this.checkCookie(cookieName);

    if (document.getElementById('policyPopup')) {
      document.getElementById('policyPopup').addEventListener('click', function () {
        // setCookie(cookieName, true, 365);
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=true" + ";" + expires + ";path=/";
        document.getElementById('pContainer').style.bottom = '-100%';
        document.getElementById('pContainer').style.transition = '2s ease-out';
        //$('.createPolicyContainer').animate({ bottom: '-100%' }, 1000);
      });
    }



    this.lndscp_msg();
    const isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
    if (isIE) {
      var ieMsg = document.getElementById('ieMsg');
      ieMsg.style.display = 'block';
    }

  }

  checkCookie(cookieName) {
    var user = this.getCookie(cookieName);
    var textLeft = "We make use of cookies. If you continue browsing our website, we assume that you consent to our use of cookies. Learn how we use cookies and how you can control them";
    var cp_linkText = "here";
    var cp_linkURL = "https://www.regalixdigital.com/cookies-policy/";
    // var pp_linkText = "here";
    // var pp_linkURL = "/privacy-policy/";
    var buttonText = "I accept"
    var buttonColor = "#39C1FD"
    if (user == "") {

      var link = document.createElement('link');
      link.innerHTML = '<link rel="stylesheet" />'
      document.getElementsByTagName('head')[0].appendChild(link);
      //$('head').append(htmlStyle);

      if (window.innerWidth < 767) {
        var htmlElement = "<div id='pContainer' class='createPolicyContainer' style='font-family: Lora, serif; left: 0;right: 0;position: fixed;width: 100%;padding: 45px 20px;bottom: 0;box-sizing: border-box;background: rgba(0, 0, 0, 0.8);color: #fff; font-size: 14px; z-index: 9999999999;'><div class='content-wrapper' style='margin:auto;max-width: 1320px;border:0;padding:0'><div class='content-wrapper-text' style='color: #fff;font-size: 16px;width: 100%;display:inline-block;vertical-align:middle;line-height:25px'>" + textLeft + " <a target='_blank' href='" + cp_linkURL + "' style='font-size: 14px;color: #39C1FD;text-decoration: underline;cursor: pointer;'>" + cp_linkText + "</a>.</div><div class='btn-wrapper' style='color: #fff;font-size: 14px;vertical-align:middle;text-align:right;'><button style='font-size: 16px;color: #000;cursor: pointer;background:" + buttonColor + ";color: #fff;border: 0;padding: 5px 30px;border-radius:25px;position: relative; font-family: Poppins, serif;margin:10px 0 0 0;' type='button' id='policyPopup' class='createPolicy'>" + buttonText + "</button></div></div></div>";
      } else {
        var htmlElement = "<div id='pContainer' class='createPolicyContainer' style='font-family: Lora, serif; left: 0;right: 0;position: fixed;width: 100%;padding: 45px 20px;bottom: 0;box-sizing: border-box;background: rgba(0, 0, 0, 0.8);color: #fff; font-size: 14px; z-index: 9999999999;'><div class='content-wrapper' style='margin:auto;max-width: 1320px;border:0;padding:0;'><div class='content-wrapper-text' style='color: #fff;font-size: 16px;width: 80%;display:inline-block;vertical-align:middle;line-height:25px'>" + textLeft + " <a target='_blank' href='" + cp_linkURL + "' style='font-size: 14px;color: #39C1FD;text-decoration: underline;cursor: pointer;'>" + cp_linkText + "</a>.</div><div class='btn-wrapper' style='color: #fff;font-size: 14px;width: 20%;display: inline-block;vertical-align:middle;text-align:right;'><button style='font-size: 16px;color: #000;cursor: pointer;background:" + buttonColor + ";color: #fff;border: 0;padding: 5px 30px;border-radius:25px;position: relative; font-family: Poppins, serif;' type='button' id='policyPopup' class='createPolicy'>" + buttonText + "</button></div></div></div>";
      }
      var div = document.createElement('div');
      div.innerHTML = htmlElement;
      document.getElementsByTagName('body')[0].appendChild(div);
      //$('body').append(htmlElement);
    } else {
      // this.loadAnalytics();
    }
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  lndscp_msg() {

    if (window.innerWidth < 767) {
      window.addEventListener('orientationchange', function () {
        if (window.orientation == 0) {
          var element = document.getElementById('landScapeMsg');
          element.classList.remove('lndscape');
          element.style.display = 'none';
        } else {
          var element = document.getElementById('landScapeMsg');
          element.classList.remove('lndscape');
          element.style.display = 'block';
        }
      });
    }
  }
}







