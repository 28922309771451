import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { Router } from '@angular/router';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgsRevealService } from 'ngx-scrollreveal';

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css']
})
export class WhatWeDoComponent implements OnInit {
  public hoveredItem;
  public activeImg = false;
  public svgStart2 = false;
  public afterRevealSubscription;
  public countPageBottom = 0;
  constructor(private digService: DigitalService, private router: Router, private revealService: NgsRevealService) { }

  public svgHoverOne: boolean = false;
  public svgHoverTextOne: boolean = false;
  public svgHoverTwo: boolean = false;
  public svgHoverTextTwo: boolean = false;
  public svgHoverThree: boolean = false;
  public svgHoverTextThree: boolean = false;


  ngOnInit() {
    window.scroll(0, 0);
    this.afterRevealSubscription = this.revealService.beforeReveal$.subscribe(
      (el: HTMLElement) => {
        if (el.id == "footer-line") {
          setTimeout(() => {
            this.svgStart2 = true;
          }, 100);
        }
      });
  }

  mouseEnter(ele) {
    if (ele === 1) {
      this.svgHoverOne = true;
      this.svgHoverTextOne = true;
    }
    else if (ele === 2) {
      this.svgHoverTwo = true;
      this.svgHoverTextTwo = true;
    }
    else if (ele === 3) {
      this.svgHoverThree = true;
      this.svgHoverTextThree = true;
    }
  }
  mouseLeave(ele) {
    if (ele === 1) {
      this.svgHoverOne = false;
      this.svgHoverTextOne = false;
    }
    else if (ele === 2) {
      this.svgHoverTwo = false;
      this.svgHoverTextTwo = false;
    }
    else if (ele === 3) {
      this.svgHoverThree = false;
      this.svgHoverTextThree = false;
    }
  }
  setId(el) {
    this.digService.setSectionID = el;
    this.router.navigateByUrl('/capabilities');
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}
