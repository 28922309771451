import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgsRevealService } from 'ngx-scrollreveal';

@Component({
  selector: 'app-work-details-two',
  templateUrl: './work-details-two.component.html',
  styleUrls: ['./work-details-two.component.css']
})
export class WorkDetailsTwoComponent implements OnInit {
  public data;
  public userSubscription;
  public innerData;
  public tempData;
  public activeImg = false;
  public afterRevealSubscriptionNew;
  public hoveredItem;
  constructor(private route: ActivatedRoute, private digService: DigitalService, private revealService: NgsRevealService, private router: Router) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.userSubscription = this.route.params.subscribe(
      (params) => {
        this.innerData = params.id;
        this.tempData = this.digService.tempContentData[this.innerData];
        this.revealService.sync();
      });
  }
  mouseover() {
    this.activeImg = true;
  }
  mouseout() {
    this.activeImg = false;
  }

}
