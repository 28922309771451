import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgsRevealService } from 'ngx-scrollreveal';
import { ScrollEvent } from 'ngx-scroll-event';
declare let $;



@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  public techText = true;
  public designText = false;
  public businessText = false;
  public creativeText = false;

  public afterRevealSubscription;
  public hoveredItem;
  public activeImg = false;
  constructor(private router: Router) { }

  ngOnInit() {
    window.scroll(0, 0);
    // window.onscroll = () => {
    //   var d = document.documentElement;
    //   var offset = d.scrollTop + window.innerHeight;
    //   var height = d.offsetHeight;

    //   console.log('offset = ' + offset);
    //   console.log('height = ' + height);

    //   if (offset === height) {
    //     this.router.navigateByUrl("/contact-us");
    //   }
    // };
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }


}
