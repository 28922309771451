import { Component, OnInit } from '@angular/core';
import { NgsRevealService } from 'ngx-scrollreveal';
import "node_modules/drawsvg";
declare let $;
@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {
  public hoveredItem;
  public activeImg = false;
  slideConfig = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 15000,
    pauseOnHover: false,
    pauseOnFocus: false
  };


  public afterRevealSubscription;
  public svgStart = false;
  constructor(private revealService: NgsRevealService) { }


  ngOnInit() {
    this.afterRevealSubscription = this.revealService.afterReveal$.subscribe(
      (el: HTMLElement) => {
        if (el.id == "svg-wrapper") {
          this.svgStart = true;
        }
      });
    var mySVG = $('.svg').drawsvg({
      duration: 2000,
    });
    mySVG.drawsvg('animate');
  }

  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }
}
