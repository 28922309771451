import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


// const httpOptions = {
//   headers: new HttpHeaders({'Content-Type': 'application/json'})
// };
@Injectable({
  providedIn: 'root'
})
export class DigitalService {
  public setSectionID;
  public url = "assets/content-data.json";

  public companyLogo = [
    './assets/images/Google.svg',
    './assets/images/vmware.svg',
    './assets/images/hitachi.svg',
    './assets/images/PureStorageLogo.svg',
    './assets/images/Amazon.svg',
    './assets/images/Oracle.svg',
    './assets/images/NetApp.svg',
    './assets/images/DELL.svg',
    './assets/images/SeagateLogo.svg'
  ];
  public filters = [
    'Interactive Apps',
    'Enablement Apps',
    'VR/AR/3D',
    'Technical Videos',
    'Brand Videos',
    'Insights'
  ];

  public workcontentFiltered = [];
  public workcontent = [
    {
      'title': 'Pure Storage',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'MSFT',
      'desc': 'Harness data to enable insights with Pure’s intelligent solutions.',
      'background': 'portfolio-blk-1',
      'grad': 'purple-grad',
      'landing': 'msft',
      'url': '/work-details/',
    },
    {
      'title': 'WELSPUN',
      'span_title': 'APP ',
      'type': ['app'],
      'sub_title': 'Jungle Dance Off',
      'desc': 'Welspun needed better market visibility, so we focused on improving ...',
      'background': 'portfolio-blk-2',
      'grad': 'blue-grad',
      'landing': 'jungle-app',
      'url': '/work-details/apps/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'vSphere 6.7',
      'desc': 'By delivering a refreshed core infrastructure platform, VMware vSphere 6.7 ... ',
      'background': 'portfolio-blk-3',
      'grad': 'purple-grad',
      'landing': 'vsphere',
      'url': '/work-details/',
    },
    {
      'title': 'SURFACE TECH',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'Juno',
      'desc': 'Juno XP enhances performance characteristics, reduces CO2 emissions ... ',
      'background': 'portfolio-blk-4',
      'grad': 'purple-grad',
      'landing': 'juno',
      'url': '/work-details/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'AR/VR/3D ',
      'type': ['3d'],
      'sub_title': '3D VCF App ',
      'desc': 'VMware needed an interactive asset to highlight the benefits ... ',
      'background': 'portfolio-blk-5',
      'grad': 'blue-grad',
      'landing': 'vcf-3d',
      'url': '/work-details/apps/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'Videos',
      'type': ['video'],
      'sub_title': 'vCloud Director',
      'desc': 'VMware vCloud Director is a cloud management platform that powers ... ',
      'background': 'vcloud-blk',
      'grad': 'purple-grad',
      'landing': 'vcloud-director',
      'url': '/work-details/',
    },
    {
      'title': 'VMware',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'VOA',
      'desc': 'VOA puts your customer on a journey towards self-driving ... ',
      'background': 'voa-blk',
      'grad': 'purple-grad',
      'landing': 'voa',
      'url': '/work-details/',
    },

    {
      'title': 'NETAPP',
      'span_title': 'Apps ',
      'type': ['app'],
      'sub_title': 'OnTap',
      'desc': 'NetApp approached Regalix to design an infographic for ONTAP ... ',
      'background': 'portfolio-blk-8',
      'grad': 'purple-grad',
      'landing': 'ontap',
      'url': '/work-details/apps/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'APPS ',
      'type': ['app'],
      'sub_title': 'vSAN Infographic',
      'desc': 'VMware wanted to showcase Virtual SAN with an eye-catching infographic. ',
      'background': 'vsan-infographic',
      'grad': 'blue-grad',
      'landing': 'vsan-infographic',
      'url': '/work-details/apps/',
    },
    {
      'title': 'PURE STORAGE',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'ES2',
      'desc': 'Pure Storage’s Evergreen Storage Service delivers the only 100% OpEx ... ',
      'background': 'es2-blk',
      'grad': 'purple-grad',
      'landing': 'es2',
      'url': '/work-details/',
    },
    {
      'title': 'CISCO',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'Turbonomics',
      'desc': 'Let your business soar with intent-based optimization from Cisco. ',
      'background': 'turbonics-blk',
      'grad': 'purple-grad',
      'landing': 'turbonomics',
      'url': '/work-details/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'Apps ',
      'type': ['app'],
      'sub_title': 'VCN Assessment Tool',
      'desc': 'VMware wanted to highlight how their Virtual Cloud Network drives ... ',
      'background': 'portfolio-blk-9',
      'grad': 'blue-grad',
      'landing': 'vcn-assessment',
      'url': '/work-details/apps/',
    },
    {
      'title': 'ORACLE',
      'span_title': 'APPS ',
      'type': ['app'],
      'sub_title': 'ROI Calculator',
      'desc': 'Oracle Marketing Cloud tasked us with acquainting prospects with their benefits. ',
      'background': 'roi-blk',
      'grad': 'blue-grad',
      'landing': 'roi-calculator',
      'url': '/work-details/apps/',
    },

    {
      'title': 'VMWARE',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'vSphere Big Data',
      'desc': 'Innovation is powered by Big Data. ',
      'background': 'vsphere-big-data-blk',
      'grad': 'purple-grad',
      'landing': 'vsphere-big-data',
      'url': '/work-details/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'vRNI',
      'desc': 'VMware vRealize Network Insights provides you with 360-degree visibility ... ',
      'background': 'vrni-blk',
      'grad': 'purple-grad',
      'landing': 'vrni',
      'url': '/work-details/',
    },
    {
      'title': 'NVIDIA',
      'span_title': 'Videos ',
      'type': ['video'],
      'sub_title': 'VMware Video',
      'desc': 'Nvidia and VMware have partnered to make your data center more agile ... ',
      'background': 'nvdia-blk',
      'grad': 'purple-grad',
      'landing': 'vmware-video',
      'url': '/work-details/',
    },
    {
      'title': 'WHITEHAT',
      'span_title': 'APPS ',
      'type': ['app'],
      'sub_title': 'TCO',
      'desc': 'Whitehat Security needed to show potential clients the edge their services ... ',
      'background': 'tco-blk',
      'grad': 'blue-grad',
      'landing': 'tco',
      'url': '/work-details/apps/',
    },
    {
      'title': 'HITACHI',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'Vantara',
      'desc': 'Are you ready to disrupt the way you think? ',
      'background': 'vantara-blk',
      'grad': 'purple-grad',
      'landing': 'vantara',
      'url': '/work-details/',
    },
    {
      'title': 'ORACLE',
      'span_title': 'APPS ',
      'type': ['app'],
      'sub_title': 'Marketing Assessment',
      'desc': 'Oracle approached us to build an application that would let users gauge ... ',
      'background': 'oracle-marketting-blk',
      'grad': 'blue-grad',
      'landing': 'marketing-assessment',
      'url': '/work-details/apps/',
    },

    {
      'title': 'VMWARE',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'vRealize Suite with VMware Cloud on AWS',
      'desc': 'vRealize provides visibility into workload dependencies and ... ',
      'background': 'vrealize-cloud-blk',
      'grad': 'purple-grad',
      'landing': 'vrealize-suite-with-vmware-cloud-on-aws',
      'url': '/work-details/',
    },
    {
      'title': 'VMWARE',
      'span_title': 'VIDEOS ',
      'type': ['video'],
      'sub_title': 'vSphere Quick Boot',
      'desc': 'Elevating customer experience to a whole new level. ',
      'background': 'vsphere-quick-boot-blk',
      'grad': 'blue-grad',
      'landing': 'vsphere-quick-boot',
      'url': '/work-details/',
    },

    {
      'title': 'Capabilities',
      'span_title': ' ',
      'type': ['video', 'app', '3d'],
      'sub_title': '',
      'desc': "<div style=\" \" class=\"capBtn\" >Continue <span style=\"font-size:26px;margin-top:25px;vertical-align:middle;\"> &rarr;</span></div>",
      'background': 'capabilities-block',
      'grad': '',
      'landing': 'capabilities',
      'url': '/capabilities/',
    },

  ];

  public innerPageFs = {
    'work': {
      'img': './assets/images/Mask.svg',
      'heading': 'Work',
      'desc': 'A selection of our favorites'
    },
    'capabilities': {
      'img': './assets/images/capabilty.png',
      'heading': 'Capabilities',
      'desc': 'What we can do'
    },
    'about': {
      'img': './assets/images/head-mechs.jpg',
      'heading': 'About',
      'desc': 'How we think and how we work'
    },
    'contact-us': {
      'img': './assets/images/contact-img.jpg',
      'heading': 'Contact',
      'desc': 'Want to work with us?'
    }
  };

  public tempContentData = {
    'msft': {
      'category': 'Videos',
      'client': 'Pure Storage',
      'title': 'MSFT',
      'bannerImg': 'assets/images/msft-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': 'Harness data to enable insights with Pure’s intelligent solutions.',
      'src': 'https://player.vimeo.com/video/238567908',
      'summary': "Pure Storage wanted a video to highlight their Microsoft solutions. Our challenge was to find a style that would communicate the required features while capturing the viewer's attention. We created an animation aesthetic that complemented Pure Storage's design, condensing the information to make the final product engaging and easy to understand.",
      'summaryImg': 'assets/images/msft-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'vSphere 6.7',
      'nextBannerImg': 'assets/images/vsphere-banner.jpg',
      'nextLink': '/work-details/vsphere',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vsphere': {
      'category': 'Videos',
      'client': 'VMware',
      'title': 'vSphere 6.7',
      'bannerImg': 'assets/images/vsphere-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/264962827',
      'headline': 'By delivering a refreshed core infrastructure platform, VMware vSphere 6.7 continues to help IT overcome new challenges.',
      'summary': 'VMware wanted to showcase their all-new vSphere 6.7 by demonstrating its technological innovation in an engaging way. We upgraded their iconography to present vSphere 6.7 as a modern, forward-looking service, spotlighting its new capabilities and upgrades in a simple and effective way.',
      'summaryImg': 'assets/images/vsphere-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'vCloud Director',
      'nextBannerImg': 'assets/images/vcloud-banner.jpg',
      'nextLink': '/work-details/vcloud-director',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vcloud-director': {
      'category': 'Videos',
      'client': 'VMware',
      'title': 'vCloud Director',
      'bannerImg': 'assets/images/vcloud-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/297311524',
      'headline': 'VMware vCloud Director is a cloud management platform that powers a network of almost a thousand cloud providers to guide your digital transformation.',
      'summary': 'Innovative animations emphasize the impact of VMware vCloud Director on business and how it revolutionizes the way IT implements and manages cloud resources.',
      'summaryImg': 'assets/images/vcloud-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'vSphere Big Data',
      'nextBannerImg': 'assets/images/big-data-banner.jpg',
      'nextLink': '/work-details/vsphere-big-data',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vsphere-big-data': {
      'category': 'Videos',
      'client': 'VMware',
      'title': 'vSphere Big Data',
      'bannerImg': 'assets/images/big-data-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/271249243',
      'headline': 'Innovation is powered by Big Data.',
      'summary': 'By drawing an analogy between the applications of Big Data and the performance of a car, this video highlights the potential of Big Data to enable predictive analytics and identify business trends.',
      'summaryImg': 'assets/images/big-data-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'VOA',
      'nextBannerImg': 'assets/images/voa-banner.jpg',
      'nextLink': '/work-details/voa',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'voa': {
      'category': 'Videos',
      'client': 'VMware',
      'title': 'VOA',
      'src': 'https://player.vimeo.com/video/264964877',
      'bannerImg': 'assets/images/voa-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'headline': 'VOA puts your customer on a journey towards self-driving operations for their software-defined data center.',
      'summary': 'VMware tasked us with creating an asset for their vRealize Optimization Assessment (VOA). We delivered a whimsical and engaging video comparing the ingredients used in a bakery to the resources of a data center.',
      'summaryImg': 'assets/images/voa-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'vRNI',
      'nextBannerImg': 'assets/images/vrealize-banner.jpg',
      'nextLink': '/work-details/vrni',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vrni': {
      'category': 'Videos',
      'client': 'VMware',
      'title': 'vRNI',
      'bannerImg': 'assets/images/vrni-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/264965304',
      'headline': 'VMware vRealize Network Insights provides you with 360-degree visibility to help you troubleshoot connectivity issues.',
      'summary': 'VMware wanted to demonstrate the capabilities of vRealize Network Insight. To explain this highly technical process, we drew an analogy between managing a high-traffic environment and operations in software-defined networking and security.',
      'summaryImg': 'assets/images/vrni-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Nvidia',
      'nextTitle': 'VMware Video',
      'nextLink': '/work-details/vmware-video',
      'nextBannerImg': 'assets/images/nvidia-banner.jpg',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vmware-video': {
      'category': 'Videos',
      'client': 'Nvidia',
      'title': 'VMware Video',
      'bannerImg': 'assets/images/nvidia-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/316047341',
      'headline': 'Nvidia and VMware have partnered to make your data center more agile.',
      'summary': 'VMware needed an asset to highlight their partnership with Nvidia. We employed sophisticated and detailed imagery to convey both NVIDIA’s powerful GPU technology and vSphere’s virtual deployment capabilities.',
      'summaryImg': 'assets/images/vrni-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Hitachi',
      'nextTitle': 'Vantara',
      'nextBannerImg': 'assets/images/vantara-banner.jpg',
      'nextLink': '/work-details/vantara',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vantara': {
      'category': 'Videos',
      'client': 'Hitachi',
      'src': 'https://player.vimeo.com/video/279223784',
      'title': 'Vantara',
      'bannerImg': 'assets/images/vantara-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': 'Are you ready to disrupt the way you think?',
      'summary': 'We were tasked with creating the intro video for Hitachi’s first global leadership meeting event after the launch of Vantara. The video needed to be engaging, disruptive and echo the leadership ethos of Hitachi Vantara.',
      'summaryImg': 'assets/images/vantara-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Vmware',
      'nextTitle': 'vSphere Quick Boot',
      'nextBannerImg': 'assets/images/quick-boot-banner.jpg',
      'nextLink': '/work-details/vsphere-quick-boot',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vsphere-quick-boot': {
      'category': 'Videos',
      'client': 'Vmware',
      'title': 'vSphere Quick Boot',
      'src': 'https://player.vimeo.com/video/273884343',
      'bannerImg': 'assets/images/quick-boot-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': 'Elevating customer experience to a whole new level.',
      'summary': 'We used creative animation and a narrative voiceover to explain how VMware 6.7’s Single Reboot and Quick Boot features enable you to enhance security and reduce the operational complexity of a growing infrastructure.',
      'summaryImg': 'assets/images/quick-boot-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'VMware',
      'nextTitle': 'vRealize Suite with VMware Could on AWS',
      'nextBannerImg': 'assets/images/vrealize-banner.jpg',
      'nextLink': '/work-details/vrealize-suite-with-vmware-could-on-aws',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'vrealize-suite-with-vmware-cloud-on-aws': {
      'category': 'Videos',
      'client': 'Vmware',
      'src': 'https://player.vimeo.com/video/244970590',
      'title': 'vRealize Suite with VMware Cloud on AWS',
      'bannerImg': 'assets/images/vrealize-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': ' vRealize provides visibility into workload dependencies and helps you make effective migration decisions.',
      'summary': 'Using attractive visuals and a clear-cut narrative, we emphasized the benefits of using vRealize Suite to optimize the performance of applications on private and VMware Clouds on AWS.',
      'summaryImg': 'assets/images/vrealize-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Surface Tech',
      'nextTitle': 'Juno',
      'nextBannerImg': 'assets/images/juno-banner.jpg',
      'nextLink': '/work-details/juno',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'juno': {
      'category': 'Videos',
      'client': 'Surface Tech',
      'title': 'Juno',
      'src': 'https://player.vimeo.com/video/316049203',
      'bannerImg': 'assets/images/juno-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': ' Juno XP enhances performance characteristics, reduces CO2 emissions and saves costs.',
      'summary': 'Surface Tech required an asset that would convey how their core innovative technology offers cutting-edge solutions for a wide array of building applications. Using a sharp design style and captivating animations, we created an engaging visual experience that placed the messaging front and center.',
      'summaryImg': 'assets/images/juno-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Pure Storage',
      'nextTitle': 'ES2',
      'nextBannerImg': 'assets/images/es2-banner.jpg',
      'nextLink': '/work-details/es2',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'es2': {
      'category': 'Videos',
      'client': 'Pure Storage',
      'title': 'ES2',
      'bannerImg': 'assets/images/es2-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'src': 'https://player.vimeo.com/video/297301567',
      'headline': 'Pure Storage’s Evergreen Storage Service delivers the only 100% OpEx on-premises storage-as-a-service.',
      'summary': 'Aligned with Pure Storage’s design style, we highlighted the technology that Evergreen Storage Service provides and focused on its efficiency.',
      'summaryImg': 'assets/images/es2-summary.jpg',
      'nextCat': 'Videos',
      'nextClient': 'Cisco',
      'nextTitle': 'Turbonomics',
      'nextBannerImg': 'assets/images/turbonomics-banner.jpg',
      'nextLink': '/work-details/turbonomics',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'turbonomics': {
      'category': 'Videos',
      'client': 'Cisco',
      'title': 'Turbonomics',
      'src': 'https://player.vimeo.com/video/316049947',
      'bannerImg': 'assets/images/turbonomics-banner.jpg',
      'bannerBg': 'rgba(85,38,167,1)',
      'headline': 'Let your business soar with intent-based optimization from Cisco.',
      'summary': 'Cisco required an asset that would explain the features and benefits of their Workload Optimization Manager. Using stock footage combined them with futuristic animation, we showcased how businesses can benefit from implementing intent-based optimization.',
      'summaryImg': 'assets/images/turbonomics-summary.jpg',
      'nextCat': 'Apps',
      'nextClient': 'Welspun',
      'nextTitle': 'Jungle App',
      'nextBannerImg': 'assets/images/jungle-banner.jpg',
      'nextLink': '/work-details/apps/jungle-app',
      'btnText': 'SEE FULL SITE',
      'how': {
        'partOne': 'Creative Writing',
        'partTwo': 'Voice Over Artist',
        'partThree': 'Animation'
      }
    },
    'jungle-app': {
      'category': 'Apps',
      'client': 'Welspun',
      'title': 'Jungle App',
      'bannerImg': 'assets/images/jungle-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'problem': 'Welspun needed better market visibility, so we focused on improving their brand recall. ',
      'probImg': 'assets/images/jungle-obj.svg',
      'whatTxt': 'We designed a viral activity that offered a fun customer interaction: combining popular dance moves with the characters from the ‘Jungle Rug’ story.',
      'appLink': 'https://static.regalixdigital.com/regalixdigital/videos/Tilt_Spin_tales_V3.mp4',
      'whatImg': 'assets/images/jungle-what.png',
      'catalogue': 'Augmented Reality was the perfect medium for this experience, highlighting the fun of TILT/Welspun’s characters as a brand.',
      'nextCat': 'Apps',
      'nextClient': 'NetApp',
      'nextTitle': 'OnTap',
      'nextBannerImg': 'assets/images/ontap-banner.png',
      'nextLink': '/work-details/apps/ontap',
      'btnText': '',
      'btnClass': 'hide'
    },
    'ontap': {
      'category': 'Apps',
      'client': 'NetApp',
      'title': 'OnTap',
      'bannerImg': 'assets/images/ontap-banner.png',
      'bannerBg': 'rgba(0,174,255,1)',
      'problem': 'NetApp approached Regalix to design an infographic for ONTAP, a revolutionary data management platform inspired by beer taps.',
      'probImg': 'assets/images/jungle-obj.svg',
      'appLink': 'https://netapp.regalixdigital.com/ontap/',
      'whatTxt': 'We took the opportunity to create a fun animated, scroll-based infographic, which entertains as it informs.',
      'whatImg': 'assets/images/ontap-what.png',
      'catalogue': 'Using their cheerful brand character allowed us to narrate the evolution and success of ONTAP in a lively, colorful manner.',
      'nextCat': 'Apps',
      'nextClient': 'Vmware',
      'nextTitle': 'vSAN Infographic',
      'nextBannerImg': 'assets/images/vsan-banner.jpg',
      'nextLink': '/work-details/apps/vsan-infographic',
      'btnText': 'SEE FULL SITE',
    },
    'vsan-infographic': {
      'category': 'Apps',
      'client': 'Vmware',
      'title': 'vSAN Infographic',
      'bannerImg': 'assets/images/vsan-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'appLink': 'https://vmware-stage.regalixdigital.com/vsan-infographic/',
      'problem': 'VMware wanted to showcase Virtual SAN with an eye-catching infographic.',
      'probImg': 'assets/images/jungle-obj.svg',
      'whatTxt': 'To present vSAN’s rich information without overwhelming the reader, we composed an interactive piece that allowed the viewer to ingest information at their own pace.',
      'whatImg': 'assets/images/vsan-what.png',
      'catalogue': 'This storytelling style, combined with our crisp iconography, puts the viewer in control of their own journey through VMware’s offering.',
      'nextCat': 'Apps',
      'nextClient': 'Whitehat',
      'nextTitle': 'TCO',
      'nextBannerImg': 'assets/images/tco-banner.jpg',
      'nextLink': '/work-details/apps/tco',
      'btnText': 'SEE FULL SITE',
    },
    'tco': {
      'category': 'Apps',
      'client': 'Whitehat',
      'title': 'TCO',
      'bannerImg': 'assets/images/tco-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'appLink': 'https://www.regalixdigital.com/showcase/Projects/whitehat/tco/',
      'problem': 'Whitehat Security needed to show potential clients the edge their services have over their competitors.',
      'probImg': 'assets/images/tco-obj.svg',
      'whatTxt': 'We built an application that would let users compare the total cost of ownership of Whitehat’s services against the alternatives.',
      'whatImg': 'assets/images/tco-what.png',
      'catalogue': 'Using graphs and variable factors such as location and rates, we provided the user with an accurate estimation of the TCO.',
      'nextCat': 'Apps',
      'nextClient': 'Vmware',
      'nextTitle': 'VCN Assesment Tool',
      'nextBannerImg': 'assets/images/vcn-banner.jpg',
      'nextLink': '/work-details/apps/vcn-assessment',
      'btnText': 'SEE FULL SITE',
    },
    'vcn-assessment': {
      'category': 'Apps',
      'client': 'Vmware',
      'title': 'VCN Assessment Tool',
      'bannerImg': 'assets/images/vcn-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'appLink': '/assets/images/vcn-what.png',
      'problem': 'VMware wanted to highlight how their Virtual Cloud Network drives new innovation and business opportunities.',
      'probImg': 'assets/images/tco-obj.svg',
      'whatTxt': 'We created a 10-minute survey that provides a personalized report and guidance on how a Virtual Cloud Network built on VMware NSX technology can help users drive your business forward.',
      'whatImg': 'assets/images/vcn-what.png',
      'catalogue': "The application provides the user with an in-depth report that summarizes the survey responses of the organization's current capabilities against each of the indicated IT priorities.",
      'nextCat': 'Apps',
      'nextClient': 'Oracle',
      'nextTitle': 'ROI Calculator',
      'nextBannerImg': 'assets/images/oracle-roi-banner.jpg',
      'nextLink': '/work-details/apps/roi-calculator',
      'btnText': 'SEE FULL SITE',
      'btnClass': 'hide'
    },
    'roi-calculator': {
      'category': 'Apps',
      'client': 'Oracle',
      'title': 'ROI Calculator',
      'appLink': 'https://www.regalixdigital.com/showcase/Projects/oracle/ROI/',
      'bannerImg': 'assets/images/oracle-roi-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'problem': 'Oracle Marketing Cloud tasked us with acquainting prospects with their benefits.',
      'probImg': 'assets/images/tco-obj.svg',
      'whatTxt': 'We designed an ROI calculator that required minimum inputs while giving maximum information, in exchange for sharing contact information to download the report.',
      'catalogue': "The result was a measurable increase in leads for Oracle's team.",
      'whatImg': 'assets/images/oracle-roi-what.png',
      'nextCat': 'Apps',
      'nextClient': 'Oracle',
      'nextTitle': 'Marketing Assessment',
      'nextBannerImg': 'assets/images/oracle-mark-banner.jpg',
      'nextLink': '/work-details/apps/marketing-assessment',
      'btnText': 'SEE FULL SITE',
    },
    'marketing-assessment': {
      'category': 'Apps',
      'client': 'Oracle',
      'appLink': 'https://www.regalixdigital.com/showcase/Projects/oracle/mma/',
      'title': 'Marketing Assessment',
      'bannerImg': 'assets/images/oracle-mark-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'problem': 'Oracle approached us to build an application that would let users gauge their own marketing techniques and assess where they stand as a modern marketer.',
      'probImg': 'assets/images/tco-obj.svg',
      'whatTxt': 'We built an application that surveys users on different marketing parameters and techniques in order to calculate the competency of their marketing strategies, allowing users to rate themselves on a scale of 0-100.',
      'whatImg': 'assets/images/oracle-mark-what.png',
      'catalogue': 'By taking this quick survey, users can get an estimate of their position as a modern marketer, ranging from Beginner to Guru.',
      'nextCat': 'AR/VR/3D',
      'nextClient': 'Vmware',
      'nextTitle': '3D VCF App',
      'nextBannerImg': 'assets/images/vcf-banner.jpg',
      'nextLink': '/work-details/apps/vcf-3d',
      'btnText': 'SEE FULL SITE',
    },
    'vcf-3d': {
      'category': 'Apps',
      'client': 'Vmware',
      'title': '3D VCF App',
      'appLink': 'https://www.regalixdigital.com/concepts/vcf/',
      'bannerImg': 'assets/images/vcf-banner.jpg',
      'bannerBg': 'rgba(0,174,255,1)',
      'problem': 'VMware needed an interactive asset to highlight the benefits of VMware Cloud Foundation.',
      'probImg': 'assets/images/tco-obj.svg',
      'whatTxt': 'We created an immersive 3D landscape as a metaphor for Cloud Foundation’s features and components.',
      'whatImg': 'assets/images/vcf-what.png',
      'catalogue': 'We designed the cityscape to encourage exploration, engaging users with interactions and informative assets.',
      'nextCat': 'Videos',
      'nextClient': 'Pure Storage',
      'nextTitle': 'MSFT',
      'nextBannerImg': 'assets/images/msft-banner.jpg',
      'nextLink': '/work-details/msft',
      'btnText': 'SEE FULL SITE',
    },



  }

  constructor(private http: HttpClient, private router: Router, private _scrollToService: ScrollToService) {
  }


  getUrl() {
    const array = this.router.url.split('/');
    const a = array[1];
    return a;
  }

  public triggerScrollTo(el) {
    if (el !== '') {
      const config: ScrollToConfigOptions = {
        target: el,
        offset: 350
      };
      this._scrollToService.scrollTo(config);
    }
  }


  getConfig() {
    return this.http.get(this.url);
  }
}
