import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';
import { NgsRevealService } from 'ngx-scrollreveal';
import { Router } from '@angular/router';
import { ScrollEvent } from 'ngx-scroll-event';



@Component({
  selector: 'app-capability-page',
  templateUrl: './capability-page.component.html',
  styleUrls: ['./capability-page.component.css'],
})
export class CapabilityPageComponent implements OnInit {
  public svgHoverOne: boolean = false;
  public svgHoverTwo: boolean = false;
  public svgHoverThree: boolean = false;
  public svgHoverFour: boolean = false;
  public svgHoverFive: boolean = false;
  public afterRevealSubscription;
  public svgHoverSix: boolean = false;
  public hoveredItem;
  public activeImg = false;
  // public countPageBottom = 0;
  constructor(private digService: DigitalService, private revealService: NgsRevealService, private router: Router) { }
  public url;
  public getSectionID;

  ngOnInit() {
    window.scroll(0, 0);
    this.getSectionID = this.digService.setSectionID;
    this.url = this.digService.getUrl();
    if (this.getSectionID !== undefined) {
      this.digService.triggerScrollTo(this.getSectionID);
    }

    // window.onscroll = (): void => {
    //   var d = document.documentElement;
    //   var offset = d.scrollTop + window.innerHeight;
    //   var height = d.offsetHeight;

    //   console.log('offset = ' + offset);
    //   console.log('height = ' + height);

    //   if (offset === height) {
    //     this.router.navigateByUrl("/work");
    //   }
    // };
  }

  mouseEnter(ele) {
    this.hoveredItem = ele;
    this.activeImg = true;
    if (ele === 1) {
      this.svgHoverOne = true;
    }
    else if (ele === 2) {
      this.svgHoverTwo = true;
    }
    else if (ele === 3) {
      this.svgHoverThree = true;
    }
    else if (ele === 4) {
      this.svgHoverFour = true;
    }
    else if (ele === 5) {
      this.svgHoverFive = true;
    }
    else if (ele === 6) {
      this.svgHoverSix = true;
    }
  }
  mouseLeave(ele) {
    this.hoveredItem = ele;
    this.activeImg = false;
    if (ele === 1) {
      this.svgHoverOne = false;
    }
    else if (ele === 2) {
      this.svgHoverTwo = false;
    }
    else if (ele === 3) {
      this.svgHoverThree = false;
    }
    else if (ele === 4) {
      this.svgHoverFour = false;
    }
    else if (ele === 5) {
      this.svgHoverFive = false;
    }
    else if (ele === 6) {
      this.svgHoverSix = false;
    }
  }
  mouseover(e) {
    this.hoveredItem = e;
    this.activeImg = true;
  }
  mouseout(e) {
    this.hoveredItem = e;
    this.activeImg = false;
  }

}
