import { Component, OnInit } from '@angular/core';
import { DigitalService } from '../digital.service';

@Component({
  selector: 'app-work-fs',
  templateUrl: './work-fs.component.html',
  styleUrls: ['./work-fs.component.css']
})
export class WorkFsComponent implements OnInit {
  public url;
  public serviceInstance;
  public img;
  public heading;
  public desc;
  constructor(private digService: DigitalService) { }
  
  ngOnInit() {
    setTimeout(() => {
      this.digService.getUrl();
      this.url = this.digService.getUrl();
      this.serviceInstance = this.digService.innerPageFs[this.url];
      this.img = this.serviceInstance['img'];
      this.heading = this.serviceInstance['heading'];
      this.desc = this.serviceInstance['desc'];
    }, 100);

  }

}
